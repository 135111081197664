/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState } from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import ParseContent from 'components/shared/ParseContent'
import { Button } from 'components/elements'

// SVG
import Logo from 'img/logo-white.inline.svg'

// Components
import { CustomLink } from 'components/shared'
import Plaatjie from '@ubo/plaatjie'

const StyledHeader = styled.nav`
  background-color: transparent;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  z-index: 99;
  
  @media screen and (max-width: 991px){
    border-bottom: 1px solid ${props => props.theme.color.face.light};
  }
`

const Container = styled.div`
  justify-content: space-between;
  align-items: center;
  height: 100%;
`

const Brand = styled(CustomLink)`
  & > svg {
    height: auto;
    width: 198px;
  }
`

const NavButton = styled.button`
  width: 80px;
  height: 50px;
  padding: 0 10px;
  text-align: center;
  z-index: 10;
`

const NavButtonStripe = styled.div`
  background-color: ${(props) => props.theme.color.text.light};
  height: 2px;
  width: 60%;
  margin: 6px auto;
`

const NavButtonTitle = styled.div`
  font-size: ${props => props.theme.font.size.sm};
  color: ${props => props.theme.color.text.light};
  text-transform: uppercase;
`

const TopMenu = styled.div`
  color: ${(props) => props.theme.color.text.light};
  font-size: ${props => props.theme.font.size.sm};

  & a {
    color: ${props => props.theme.color.text.light} !important;
    text-decoration: none !important;
  }
`

const Header = ({ setIsScrollable }) => {
  const { fields } = useStaticQuery(graphql`
    {
      fields: wordpressWpComponenten(wordpress_id: { eq: 97 }) {
        acf {
          top {
            description
          }
          menu {
            link {
              title
              url
              target
            }
            submenu {
              link {
                title
                url
              }
            }
          }
          mobile {
            background {
            localFile {
              publicURL
                childImageSharp {
                  fluid(toFormat: WEBP, maxWidth: 800, quality:100) {
                    src
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <StyledHeader>
      <Container className="container">
        <TopMenu className="d-flex justify-content-end pt-3">
          <ParseContent content={fields.acf.top.description} />
        </TopMenu>
        <MenuDesktop fields={fields} />
        <MenuMobile fields={fields} setIsScrollable={setIsScrollable} />
      </Container>
    </StyledHeader>
  )
}

const MenuWrapper = styled.div`
  background-color: ${(props) => props.theme.color.face.main};
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99;
  padding: 0;
  max-height: ${props=>props.isVisible ? '100vh': 0};
  transition: all 300ms ease-in;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`

const NavButtonCloseCross = styled.div`
  position: relative;
  width: 32px;
  height: 32px;
  margin-left: 16px;

  &:before,
  &:after {
    background-color: ${(props) => props.theme.color.text.light};
    position: absolute;
    content: ' ';
    height: 33px;
    width: 2px;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
`

const MenuBackground = styled.div`
  position: absolute !important;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
`

const MenuBackgroundOverlay = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, .1);
`

const MenuBackgroundContent = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 2;
`

const NavButtonCloseTitle = styled.div`
  color: ${props => props.theme.color.text.light};
  text-transform: uppercase;
  margin-top: 3px;
`

const MobileTop = styled.div`
  position: absolute;
  right: 0;
  top: 0;
`

const MenuMobile = ({ fields, setIsScrollable }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className="d-block d-lg-none pb-2">
      <div className="d-flex justify-content-between">
        <Brand to="/" className="mb-2">
          <Logo />
        </Brand>
        <NavButton
          className="ml-auto mt-2"
          role="button"
          onClick={() => {
            setIsOpen(!isOpen)
            setIsScrollable(false)
          }}
        >
          <NavButtonStripe />
          <NavButtonStripe />
          <NavButtonStripe />
          <NavButtonTitle>
            Menu
          </NavButtonTitle>
        </NavButton>
      </div>
      <MenuWrapper isVisible={isOpen}>
        <MenuBackground alt="Home" style={{
          backgroundImage: `url(${fields.acf.mobile.background.localFile.childImageSharp.fluid.src})`
        }}>
          <MenuBackgroundOverlay />
          <MenuBackgroundContent>
            <MobileTop className="p-3 text-center">
              <NavButtonCloseCross 
                onClick={() => {
                  setIsOpen(!isOpen)
                  setIsScrollable(true)
                }}  
              />
              <NavButtonCloseTitle>
                Sluiten
              </NavButtonCloseTitle>
            </MobileTop>
            <div className='py-5 d-flex flex-column justify-content-between h-100'>
              {fields.acf.menu.map(({ link: { url, title } }) => (
                <MobileItem key={title}>
                  <CustomLink to={url}>{title}</CustomLink>
                </MobileItem>
              ))}
              <div className="d-flex justify-content-center">
                <Button to="/afspraak-maken">Afspraak maken</Button>
              </div>
            </div>
          </MenuBackgroundContent>
        </MenuBackground>
      </MenuWrapper>
    </div>
  )
}

const MobileItem = styled.div`
  display: flex;
  justify-content: center;
  & > a {
    transition: all 300ms ease-in;
    color: ${(props) => props.theme.color.text.light};
    font-size: 24px;
    line-height: 36px;
    padding-bottom: 4px;
    border-bottom: 2px solid transparent;
    text-align: center;
    width: 180px;
    &:hover {
      border-bottom: 2px solid ${(props) => props.theme.color.face.secondary};
      text-decoration: none;
      color: ${(props) => props.theme.color.text.light};
    }
  }
`

const StyledMenuDesktop = styled.div`
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;
  border-bottom: 1px solid #e6e6e6;
`

const MenuDesktop = ({ fields }) => (
  <StyledMenuDesktop className="d-none d-lg-flex pt-2 pb-3">
    <Brand to="/" className="mr-lg-5">
      <Logo />
    </Brand>
    <Menu fields={fields} />
  </StyledMenuDesktop>
)

const Item = styled.li`
  display: flex;
  flex-direction: column;
  justify-content: center;

  & > a {
    transition: all 300ms ease-in;
    color: ${(props) => props.theme.color.text.light};
    font-family: ${(props) => props.theme.font.family.main};
    font-size: ${(props) => props.theme.font.size.sm};
    font-weight: ${(props) => props.theme.font.weight.l};
    border-bottom: 2px solid transparent;
    &[aria-current] {
    }

    &:hover {
      border-bottom: 2px solid ${(props) => props.theme.color.face.secondary};
      text-decoration: none;
      color: ${(props) => props.theme.color.text.light};
    }
  }
`

const Menu = ({ fields }) => {
  return fields.acf.menu.map(({ link, submenu }, index) => {
    if(index === (fields.acf.menu.length - 1)) {
      return <Button key={link.url} to={link.url}>{link.title}</Button>
    }

    return (
      <Item key={link.url}>
        <CustomLink to={link.url}>{link.title}</CustomLink>
        {submenu && (
          <div className="postion-relative">
            <DropDown>
              {submenu.map(({ link: subLink }) => 
                <DropDownItem key={subLink.url} to={subLink.url}>{subLink.title}</DropDownItem>
              )}
            </DropDown>
          </div>
        )}
      </Item>
    )
  })
}

const DropDown = styled.div`
  margin-top: 50px;
  position: absolute;
  overflow: hidden;
  max-height: 0;
  width: 254px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 0 4px rgba(0,0,0,0.5);
  transition: max-height 300ms ease-in;
  ${Item}:hover &{
    max-height: 300px;
  }
`

const DropDownItem = styled(CustomLink)`
  color: ${(props) => props.theme.color.text.main};
  cursor: pointer;
  padding: 10px 20px;
  display: block;
  font-family: ${(props) => props.theme.font.family.main};
  font-size: ${(props) => props.theme.font.size.sm};
  font-weight: ${(props) => props.theme.font.weight.l};
  line-height: ${(props) => props.theme.font.size.l};
  &:hover, &:active, &:focus{
    color: ${(props) => props.theme.color.text.secondary};
    text-decoration: none;
  }
  &:first-child{
    margin-top: 10px;
  }
  &:last-child{
    margin-bottom: 10px;
  }
`

export default Header
