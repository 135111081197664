/* eslint-disable import/no-unresolved */
import React from 'react'
import styled from 'styled-components'
import ReactPlayer from 'react-player'
import { ParseContent} from 'components/shared'
import clockIcon from 'img/icon-clock.svg'
import playIcon from 'img/icon-play.svg'

const Container = styled.div`
  width: 100%;
  margin: 20px 0;
  
  & h3 {
    font-size: 16px;
    line-height: 19px;
  }

  & p {
    font-size: 14px;
    line-height: 22px;
  }

  & .react-player {
    border-radius: 10px;
    overflow: hidden;
  }
`

const Time = styled.div`
  background: #012E58;
  padding: 3px 10px;
  color: white;
  border-radius: 15px;
  display: flex;
  font-size: ${(props) => props.theme.font.size.sm};
  line-height: ${(props) => props.theme.font.size.l};
  font-family: ${(props) => props.theme.font.family.secondary};
  width: fit-content;
  position: relative;
  margin-top: -15px;
  margin-right: 20px;
  margin-left: auto;
`

const Title = styled.div`
  & span {
    font-size: ${(props) => props.theme.font.size.m};
    font-weight: ${(props) => props.theme.font.weight.l};
    line-height: ${(props) => props.theme.font.size.xl};
    margin-left: 12px;
    color: ${(props) => props.theme.color.text.third};

  }
`

export default function Video({ url, duration, title }) {
  return (
    <Container>
      <ReactPlayer
        className='react-player'
        url={url}
        width='100%'
        height='250px'
      />      
      <Time>
        <img src={clockIcon} alt='clock' />
        <span className='mx-2'>{duration}</span>
      </Time>
      <Title className='p-2 mt-3 d-flex'>
        <img src={playIcon} alt='play' />
        <ParseContent content={`<span>${title}</span>`} />       
      </Title>
    </Container>
  )
}