/* eslint-disable import/no-unresolved */
/* eslint-disable react/no-array-index-key */
import React from 'react'
import styled from 'styled-components'
import {ParseContent} from 'components/shared'

const ItemContainer = styled.div`
  font-family: ${props => props.theme.font.family.secondary};
`

const BenefitContainer = styled.div`
  margin-top: 90px;
  padding: 70px 60px 60px 60px;
  background-repeat: no-repeat;
  background-position: center right;
  background-size: 'cover';
  border-radius: 25px;
  box-shadow: 0 0 4px rgba(0,0,0,0.5);

  &:before{
    border-radius: 25px;
    background-repeat: no-repeat;
  }

  @media screen and (max-width: 991px){
    padding: 40px 30px 0px 30px;
  }
`

const BenfitTitle = styled.div`
  font-weight: ${(props) => props.theme.font.weight.l};
  color: ${(props) => props.theme.color.text.main};
  background: white;
  padding: 0 20px;
  position: absolute;
  width: fit-content;
  margin-bottom: 40px;

  @media (min-width: 992px) {
    margin-top: -85px;
    font-size: ${(props) => props.theme.font.size.l};
    line-height: ${(props) => props.theme.font.size.l};
  }
  
  @media screen and (max-width: 991px){
    font-size: ${(props) => props.theme.font.size.xm};
    line-height: ${(props) => props.theme.font.size.xm};
    margin-top: -50px;
  }

  @media screen and (max-width: 767px){
    font-size: ${(props) => props.theme.font.size.xm};
    line-height: ${(props) => props.theme.font.size.xm};
    background-color: transparent;
    text-align: center;
    margin: -15px auto;
  }
`

export default function Benefit({ fields }) {
  return (
    <BenefitContainer style={{ backgroundImage: `url(${fields.background.localFile.childImageSharp.fluid.src})`, backgroundSize: 'cover', borderRadius: 25, backgroundPosition: 'right', backgroundRepeat: 'no-repeat'}}>
      <BenfitTitle>
        {fields.block.title}
      </BenfitTitle>
      <div className="row">
        {fields.block.usps.map(({ description }, index) => (
          <div key={index} className='col-lg-4 my-lg-0 my-5'>
            <Item description={description} />
          </div>
        ))}
      </div>
    </BenefitContainer>
  )
}
const Item = ({ description }) => {
  return (
    <ItemContainer>
      <ParseContent content={description} />
    </ItemContainer>
  )
}