import styled from 'styled-components'

export default styled.textarea`
  outline: none;
  font-size: ${(props) => props.theme.font.size.sm};
  line-height: ${(props) => props.theme.font.size.l};
  border: 1px solid #0C2350;
  padding: 14px 19px;
  color: #0C2350;
  border-radius: 4px;  
  height: 185px;
  width: 100%;
  color: ${(props) => props.theme.color.text.main};

  &::placeholder{
    color: ${(props) => props.theme.color.text.main};
    opacity: .5;
  }
`