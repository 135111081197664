/* eslint-disable import/no-unresolved */
/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import { CustomLink, ParseContent, GravityForm } from 'components/shared'
import Logo from 'img/logo.inline.svg'
import locationIcon from 'img/icon-location.svg'
import mailIcon from 'img/icon-mail.svg'
import phoneIcon from 'img/icon-phone.svg'
import Plaatjie from '@ubo/plaatjie'

const StyledFooter = styled.section`
  color: ${(props) => props.theme.color.text.light};
  background-size: 100% 100%;

  @media (min-width: 992px) {
    padding-top: ${(props) => (props.hasNewsletter ? '0' : '150px')};
  }

  @media screen and (max-width: 991px) {
    &:before {
      background-size: cover !important;
      background-image: none !important;
      background: ${(props) => props.theme.color.face.main};
    }

    background-size: cover !important;
    background-image: none !important;
    background: ${(props) => props.theme.color.face.main};
  }
`

const TopDescription = styled(ParseContent)`
  font-size: ${props => props.theme.font.size.sm};

  & p {
    margin: 0;
  }
`

const Brand = styled(CustomLink)`
  & > svg {
    height: 50px;
    width: 198px;
  }
`
const TopMenu = styled.div`
  border-bottom: 2px solid #e6e6e6;
`

const Feature = styled.div``

const Desc = styled(ParseContent)`
  font-family: ${(props) => props.theme.font.family.secondary};
`

const FooterLink = styled(CustomLink)`
  display: block;
  color: white;
  margin: 4px 0;
  font-size: ${(props) => props.theme.font.size.sm};
  font-family: ${(props) => props.theme.font.family.secondary};
  letter-spacing: 0.7px;
  line-height: ${(props) => props.theme.font.size.m};
  padding-bottom: 4px;
  border-bottom: 2px solid transparent;
  transition: all 300ms ease-in;
  width: fit-content;
  &:hover {
    border-bottom: 2px solid ${(props) => props.theme.color.face.secondary};
    text-decoration: none;
    color: ${(props) => props.theme.color.text.light};
  }
`

const Footer = ({ hasNewsletter = true }) => {
  const { fields } = useStaticQuery(graphql`
    {
      fields: wordpressWpComponenten(wordpress_id: { eq: 98 }) {
        acf {
          menu {
            link {
              title
              url
            }
          }
          upside {
            description_address
            description_email
            description_phone
          }
          meta {
            description
          }
          background {
            localFile {
              publicURL
              childImageSharp {
                fluid(toFormat: WEBP, maxWidth: 2000, quality:100) {
                  src
                }
              }
            }
          }
          menu_rest {
            link {
              title
              url
            }
          }
        }
      }
    }
  `)

  return (
    <StyledFooter
      hasNewsletter={hasNewsletter}
      style={{ backgroundImage: `url(${fields.acf.background.localFile.childImageSharp.fluid.src})`}}
    >
      <div className="container">
        {hasNewsletter && <Newsletter />}
        <TopMenu className="row py-4">
          <div className="col-lg-3 mb-5 my-lg-2">
            <iframe 
              src="https://cdn.ageras.com/api/rating-widget?partner_id=226116&review_count=undefined&width=250&height=190&light=0" 
              width="250" 
              height="145" 
              border="0" 
              frameborder="0" 
              style={{border: 0}} 
              allowtransparency="true" />
          </div>
          <Feature className="col-lg-3 d-flex align-items-center my-2">
            <img src={locationIcon} alt="location" />
            <div className="ml-2">
              <TopDescription content={fields.acf.upside.description_address} />
            </div>
          </Feature>
          <Feature className="col-lg-3 d-flex align-items-center my-2">
            <img src={mailIcon} alt="mail address" />
            <div className="ml-2">
              <TopDescription content={fields.acf.upside.description_email} />
            </div>
          </Feature>
          <Feature className="col-lg-3 d-flex align-items-center my-2">
            <img src={phoneIcon} alt="phone number" />
            <div className="ml-2">
              <TopDescription content={fields.acf.upside.description_phone} />
            </div>
          </Feature>
        </TopMenu>
        <div className="row py-5 px-lg-3">
          <div className="col-lg-6">
            <Desc content={fields.acf.meta.description} />
          </div>
          <div className="ml-auto col-lg-2 mt-5 mt-lg-0">
            {fields.acf.menu.map(({ link: { url, title } }, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <FooterLink to={url} key={index}>
                {title}
              </FooterLink>
            ))}
          </div>
          <div className="col-lg-2">
            {fields.acf.menu_rest.map(({ link: { url, title } }, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <FooterLink to={url} key={index}>
                {title}
              </FooterLink>
            ))}
          </div>
        </div>
      </div>
    </StyledFooter>
  )
}

const NewsletterContainer = styled.div`
  padding: 10px 100px 40px 100px;
  background: white;
  border-radius: 25px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);

  @media (min-width: 992px) {
    margin-top: 90px;
  }

  @media screen and (max-width: 991px) {
    bottom: 30px;
    position: relative;
    padding: 10px 30px 20px 30px;
  }
`

const NewsletterTitle = styled.div`
  font-size: ${(props) => props.theme.font.size.xl};
  font-weight: ${(props) => props.theme.font.weight.l};
  line-height: ${(props) => props.theme.font.size.xxl};
  color: ${(props) => props.theme.color.text.main};
  background: white;
  padding: 0 20px;
  position: relative;
  margin-top: -30px;
  width: fit-content;
  margin-bottom: 30px;
`

const NewsLetterGravityForm = styled.div`
  & .privacy {
    color: ${props => props.theme.color.text.main};
  }

  @media (min-width: 992px) {
    & .privacy {
      margin-bottom: 0 !important;
    }

    & form {
      display: flex;

      & .form-group {
        margin-bottom: 0;
      }

      & > div {
        margin: 0 15px;
      }

      & > [data-type="text"] {
        width: 25%;
      }

      & > [data-type="email"] {
        width: 45%;
      }

      & > [data-type="submit"] {
        display: flex;
      }
    }
  }
`

const Newsletter = () => {
  return (
    <NewsletterContainer>
      <NewsletterTitle>Nieuwsbrief</NewsletterTitle>
      <NewsLetterGravityForm className="flex-wrap">
        <GravityForm id={3} className="flex-grow" />
      </NewsLetterGravityForm>
    </NewsletterContainer>
  )
}

export default Footer
