import styled from 'styled-components'

export default styled.div`
  & .select__control {
    outline: none;
    font-size: ${(props) => props.theme.font.size.sm};
    line-height: ${(props) => props.theme.font.size.l};

    border: 1px solid #0C2350;
    padding: 8px 9px;
    color: ${(props) => props.theme.color.text.main};
    border-radius: 4px;
    width: 100%;
    color: ${(props) => props.theme.color.text.main};
  }

  & .select__placeholder {
    color: ${(props) => props.theme.color.text.main};
    opacity: .5;
  }
`
