/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import styled, { css } from 'styled-components'
import ButtonShell from './ButtonShell'

const StyledButton = styled.span`
  background-color: ${props => props.theme.color.face.secondary};
  border: 1px solid ${props => props.theme.color.face.secondary};
  color: ${props => props.theme.color.text.light};
  display: inline-block;
  font-size: ${(props) => props.theme.font.size.sm};
  font-family: ${(props) => props.theme.font.family.main};
  line-height: ${(props) => props.theme.font.size.m};
  text-align: center;
  cursor: pointer;
  width: ${props=>props.width === 'fluid' ? '100%' : 'fit-content'};

  & > a, & > button {
    width: 100%;
    height: 100%;
    display: block;
    background-color:transparent;
    font-size: ${(props) => props.theme.font.size.sm};
    line-height: ${(props) => props.theme.font.size.m};
    color: ${props => props.theme.color.text.light} !important;
    text-decoration: none !important;
    &:hover{
      text-decoration: none !important;
    }

    @media (min-width: 992px) {
      padding: 16px 32px;
    }

    @media (max-width: 991px) {
      padding: 8px 12px;
    }
  }

  transition: all 300ms ease-in;

  ${props => props.disabled ? css`
    opacity: .5;
  ` : css`
    &:hover{
      background-color: ${props.theme.color.text.light};

      & > a, & > button {
        color: ${props.theme.color.face.secondary} !important;
      }
    }
  `}

  @media (min-width: 992px) {
    border-radius: 25px;
  }

  @media (max-width: 991px) {
    border-radius: 20px;
  }
`

const ButtonDefault = ({ isAnchor, isCustom, to, children, className, target, width='fit', disabled = false }) => (
  <StyledButton className={className} width={width} disabled={disabled}>
    {isCustom ? children : (
      <ButtonShell to={to} isAnchor={isAnchor} target={target} disabled={disabled}>
        {children}
      </ButtonShell>
    )}
  </StyledButton>
)

export default ButtonDefault