import React from 'react'
import { ThemeProvider, createGlobalStyle } from 'styled-components'

const mainTheme = {
  color: {
    face: {
      main: '#1D3660',
      contrast: '#EB6209',
      secondary: '#23C154',
      light: '#FFFFFF'
    },

    text: {
      main: '#012E58',
      contrast: '#EB6209',
      secondary: '#23C154',
      third: '#003C72',
      light: '#FFFFFF',
      black: '#000000',
      blue: '#003466'
    },
  },

  font: {
    family: {
      main: 'Raleway',
      secondary: 'Raleway',
      // secondary: 'Alegreya Sans',
    },

    size: {
      xxs: '8px',
      xs: '10px',
      s: '12px',
      sm: '14px',
      m: '16px',
      xm: '20px',
      l: '24px',
      xl: '32px',
      xxl: '40px',
      xxxl: '50px',
    },

    weight: {
      xs: 200,
      s: 300,
      m: 400,
      l: 600,
      xl: 800,
    }
  }
}

const GlobalStyle = createGlobalStyle`
  html, body {
    height: 100%;
    overflow: ${props => props.isScrollable ? 'initial' : 'hidden'};
  }

  body {
    font-family: ${props => props.theme.font.family.main};
    font-size: ${props => props.theme.font.size.m};
    color: ${props => props.theme.color.text.main};
  }

  ${props => Object.keys(props.theme.color.face).map(key => {
    return `.color-background-${key} { background-color: ${props.theme.color.face[key]}; }`
  })}

  ${props => Object.keys(props.theme.color.text).map(key => {
    return `.color-${key} { color: ${props.theme.color.text[key]}; }`
  })}

  ${props => Object.keys(props.theme.font.family).map(key => {
    return `.font-family-${key} { font-family: ${props.theme.font.family[key]}; }`
  })}

  ${props => Object.keys(props.theme.font.size).map(key => {
    return `.font-size-${key} { font-size: ${props.theme.font.size[key]}; }`
  })}

  ${props => Object.keys(props.theme.font.weight).map(key => {
    return `.font-weight-${key} { font-weight: ${props.theme.font.weight[key]}; }`
  })}

  #rcc-confirm-button {
    background-color: ${props => props.theme.color.face.main} !important;
    color: ${props => props.theme.color.text.light} !important;
  }

  a {
    color: inherit;

    &:hover {
      color: inherit;
      text-decoration: underline;
    }
  }
`

const Theme = ({ children, isScrollable }) => (
  <ThemeProvider theme={mainTheme}>
    <GlobalStyle isScrollable={isScrollable} />
    {children}
  </ThemeProvider>
)

export default Theme