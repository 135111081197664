/* eslint-disable import/no-unresolved */
import React from 'react'
import styled from 'styled-components'
import ParseContent from 'components/shared/ParseContent'
import Plaatjie from '@ubo/plaatjie'

const Container = styled.section`
  position: relative;
  @media (max-width: 1500px) {
    height: 75% !important;
  }

  @media (max-width: 767px) {
    height: 100% !important;    
  }
`

const Content = styled(ParseContent)`
  & h2 {
    margin-bottom: 20px;

    @media (min-width: 992px) {
      font-size: ${(props) => props.theme.font.size.xxl};
      line-height: ${(props) => props.theme.font.size.xxl};
    }

    @media (max-width: 991px) {
      font-size: ${(props) => props.theme.font.size.xl};
      line-height: ${(props) => props.theme.font.size.xl};
    }
  }
`

const SimpleImage = styled(Plaatjie)`
  @media (min-width: 768px) {

    & img {
      height: 300px;
      width: 100%;
    }
  }

  @media (max-width: 767px) {
    & img {
      height: 200px;
      width: 100%;
    }
  }
`

const CustomImage = styled(Plaatjie)`
  position: absolute !important;
  top: -50px;
  right: 0px;
  width: 710px;
  height: 607px;

  & img {
    object-fit: contain !important;
  }

  @media (max-width: 991px){
    display: none !important;
  }
`

export default function SimpleTools({ fields }) {
  return (
    <>
      <Container>
        <CustomImage image={fields.image} />
        <div className="container py-5">
          <div className="row">
            <div className="col-xl-6 pr-xl-5">
              <Content className="font-family-secondary" content={fields.description} />
            </div>
          </div>
        </div>
      </Container>
      <div className="container d-xl-none d-block">
        {/* <Content className="font-family-secondary" content={fields.description} /> */}
      </div>
      <div className="d-flex d-xl-none justify-content-end">
        <SimpleImage image={fields.image} alt={fields.image.title} />
      </div>
    </>
  )
}