/* eslint-disable import/no-unresolved */
import React, { useState } from 'react'
import styled from 'styled-components'
import { ParseContent } from 'components/shared'
import parse from 'html-react-parser'

const PanelContainer = styled.div`

`
const PanelHeader = styled.div`
  color: #002E59;
  margin: 12px 0;
  cursor: pointer;
  font-weight: ${(props) => props.theme.font.weight.l};
  font-size: ${(props) => props.theme.font.size.m};
  line-height: ${(props) => props.theme.font.size.l};
  color: ${(props) => props.theme.color.text.third};
`

const PanelContent = styled(ParseContent)`
  font-family: ${(props) => props.theme.font.family.secondary};
  overflow: hidden;
  max-height: ${props=>props.active ? '400px' : 0};
  transition: all 300ms ease;
`

const Panel = ({ question, answer, activePanel, index, setActivePanel }) => {
  const active = activePanel === index

  return (
    <PanelContainer className='my-4'>
      <PanelHeader 
        role="button"
        className='d-flex justify-content-between' 
        onClick={() => {
          if(activePanel === index) {
            setActivePanel(null)  
          } else {
            setActivePanel(index)
          }
        }}
      >
        <span>
          {parse(question)}
        </span>
        <span>
          {active ? '-': '+'}
          {' '}
        </span>
      </PanelHeader>
      <PanelContent active={active} content={answer} />
    </PanelContainer>
)
}

export default function Accordion({ data }) {
  const [activePanel, setActivePanel] = useState(0)

  return (
    <div>
      {data.map(({ question, answer }, index) => {
        return (
          <Panel 
            key={question} 
            question={question} 
            answer={answer} 
            activePanel={activePanel} 
            setActivePanel={setActivePanel} 
            index={index} 
          />
        )
      })}
    </div>
  )
}
